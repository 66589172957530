import React, { useState, useEffect, useRef, useContext } from 'react'
import Graphviz from 'graphviz-react'
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_PAN } from 'react-svg-pan-zoom'
import {
  SnackbarStatus,
  workModeOptions,
  readinessDegreeOptions,
  readinessDegreeOptionsDepend,
  VehicleTypeDetails,
} from '../constants'
import { useSnackbar } from '../contexts/SnackbarContext'
import { useApiUrl, getApiUrl, httpRequests } from '../utils'
import useGetRequest from '../hooks/useGetRequest'
import { Select, getSelectedOption } from '../components'
import { authContext } from '../contexts/AuthContext'
import moment from 'moment/moment'

const CHECK_STATE_INTERVAL_VALUE = 1 * 60 * 1000
const CLASS_UNIT_ID_PREFIX = 'unit_'
const RECT_VEHICLE_CLICKABLE_CLASS = 'vehicle-clickable'
const RECT_VEHICLE_CLICKABLE_RECT_ID = 'data-vehicle-id'
const RECT_VEHICLE_CLICKABLE_TITLE_ID = 'data-status-vehicle-id'
const GRAPH_SVG_UNIT_WIDTH = 4.5
const GRAPH_SVG_UNIT_HEIGHT = '4.5pt'
const GRAPH_SVG_FONT_NAME = 'Helvetica'
const GRAPH_SVG_FONT_SIZE = 30
const GRAPH_SVG_COLOR_ONMAINTENANCE = '#000000'
const GRAPH_SVG_COLOR_ONLINE = '#33ff33'
const GRAPH_SVG_COLOR_OFFLINE = '#ff3333'
const GRAPH_SVG_IMAGE_SCALE = 0.75

const getCleanUnitName = (unitName = '') => {
  const splittedName = unitName.split(',')
  if (splittedName.length > 1) {
    return splittedName[0].trim()
  } else {
    return unitName
  }
}

const getTimeoutByReadiness = (value = '') => {
  const optionConfif = readinessDegreeOptions.find((r) => r.value.toLowerCase() === value.toLowerCase())
  return optionConfif?.timeout || null
}

const getActiveStatusConfig = (vehicle) => {
  const isWarning =
    (vehicle.hasWorkMode && vehicle.actualWorkMode !== vehicle.workMode) ||
    (vehicle.hasReadinessDegree && vehicle.actualReadinessDegree !== vehicle.readinessDegree)
  let text = ''
  if (vehicle.hasWorkMode) {
    text = isWarning ? `${vehicle.workMode || ''}/${vehicle.actualWorkMode || ''}` : vehicle.actualWorkMode
  }
  if (vehicle.hasReadinessDegree) {
    text = isWarning
      ? `${vehicle.readinessDegree || ''}/${vehicle.actualReadinessDegree || ''}`
      : vehicle.actualReadinessDegree
  }
  let isAlert = false
  let timeLeft = ''
  if (vehicle.hasReadinessDegree && vehicle.actualReadinessDegree && vehicle.actualChanged) {
    const timeout = getTimeoutByReadiness(vehicle.actualReadinessDegree)
    if (timeout) {
      const mtStart = moment()
      const mtEnd = moment(new Date(new Date(vehicle.actualChanged).getTime() + timeout))
      const msLeft = mtEnd.diff(mtStart)
      if (msLeft > 0) {
        // const monthsLeft = mtEnd.diff(mtStart, 'months')
        // const daysLeft = mtEnd.diff(mtStart, 'days')
        // const hoursLeft = mtEnd.diff(mtStart, 'hours')
        // const minutesLeft = mtEnd.diff(mtStart, 'minutes')
        // if (monthsLeft > 0) {
        //   const rest = Math.round((daysLeft / 30) * 10) / 10
        //   timeLeft = `${rest} міс.`
        // } else if (daysLeft > 0) {
        //   const rest = Math.round((hoursLeft / 24) * 10) / 10
        //   timeLeft = `${rest} дн.`
        // } else if (hoursLeft > 0) {
        //   const rest = Math.round((minutesLeft / 60) * 10) / 10
        //   timeLeft = `${rest} год.`
        // } else {
        //   timeLeft = `${minutesLeft} хвил.`
        // }
        // text = `${text} ⌛ ${timeLeft}`
      } else {
        isAlert = true
      }
    }
  }
  return {
    text,
    titleStroke: isWarning ? '#e13300' : '#1d7000',
    frameStroke: isAlert ? '#d90000' : isWarning ? '#d2c300' : '#338000',
  }
}

const getCurrentOption = (vehicle) => {
  if (vehicle) {
    if (vehicle.hasWorkMode) {
      return getSelectedOption({ label: vehicle.actualWorkMode, value: vehicle.actualWorkMode }, workModeOptions)
    }
    if (vehicle.hasReadinessDegree) {
      return getSelectedOption(
        { label: vehicle.actualReadinessDegree, value: vehicle.actualReadinessDegree },
        readinessDegreeOptions
      )
    }
  }
  return { label: '', value: '' }
}

const fillNodeStructure = (unitStructure, parentUnitId = '', hierarchy, vehicles, personalData) => {
  for (const h of hierarchy) {
    unitStructure.push({
      unitId: h.entityId,
      parentId: parentUnitId,
      unitName: getCleanUnitName(h.name),
      vehicles: getUnitVehicles(
        h.entityId,
        vehicles,
        personalData.isCommander && (personalData.unitId === h.entityId || personalData.unitId === parentUnitId)
      ),
    })
    if (h.children?.length) {
      fillNodeStructure(unitStructure, h.entityId, h.children, vehicles, personalData)
    }
  }
}

const getUnitVehicles = (unitId = '', vehicles, isChangesAllowed) => {
  const result = []
  for (const v of vehicles) {
    if (v.unitId === unitId) {
      const typeName = (v.vehicleDictionary?.vehicleType?.type || 'TCM').toLowerCase()
      const hasReadinessDegree = VehicleTypeDetails[v.vehicleDictionary?.vehicleType?.type]?.readinessDegree || false
      const hasWorkMode = VehicleTypeDetails[v.vehicleDictionary?.vehicleType?.type]?.workMode || false
      result.push({
        vehicleId: v.entityId,
        type: typeName === 'spl' ? typeName + '_fold' : typeName,
        name: v.number || '',
        hasReadinessDegree,
        hasWorkMode,
        readinessDegree: hasReadinessDegree && v.unitWorkingModes?.length ? v.unitWorkingModes[0].readinessDegree : '',
        workMode: hasWorkMode && v.unitWorkingModes?.length ? v.unitWorkingModes[0].workMode : '',
        actualReadinessDegree:
          hasReadinessDegree && v.unitWorkingModes?.length ? v.unitWorkingModes[0].actualReadinessDegree : '',
        actualWorkMode: hasWorkMode && v.unitWorkingModes?.length ? v.unitWorkingModes[0].actualWorkMode : '',
        actualChanged: v.unitWorkingModes?.length ? v.unitWorkingModes[0].actualChanged : null,
        hasConnection: v.hasConnection,
        isAvailable: v.isAvailable,
        isChangesAllowed,
        appId: v.appId,
      })
    }
  }
  return result
}

const findVehicleById = (dataStructure, vId) => {
  let result = null
  for (const u of dataStructure) {
    if (u.vehicles) {
      for (const v of u.vehicles) {
        if (v.vehicleId === vId) {
          return v
        }
      }
    }
  }
  return result
}

const improveNodeSvg = (nodeUnitOriginal, dataStructure) => {
  const cl = nodeUnitOriginal.classList
  let classUnidId = ''
  cl.forEach((n) => {
    if (n.startsWith(CLASS_UNIT_ID_PREFIX)) {
      classUnidId = n.split(CLASS_UNIT_ID_PREFIX)[1]
    }
  })
  if (classUnidId) {
    const unit = dataStructure.find((r) => r.unitId === classUnidId)
    if (unit) {
      const nodeText = nodeUnitOriginal.querySelector('text')
      const nodePolygon = nodeUnitOriginal.querySelector('polygon')
      if (nodeText && nodePolygon) {
        // main calculations
        const pointsString = nodePolygon.getAttribute('points')
        const points = pointsString.split(' ').map((point) => point.split(',').map(Number))
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        points.forEach(([x, y]) => {
          minX = Math.min(minX, x)
          minY = Math.min(minY, y)
          maxX = Math.max(maxX, x)
          maxY = Math.max(maxY, y)
        })
        const rectX = minX
        const rectY = minY
        const rectWidth = maxX - minX
        const rectHeight = maxY - minY
        // replace polygon with rect (rounded edges)
        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        rect.setAttribute('x', rectX)
        rect.setAttribute('y', rectY)
        rect.setAttribute('rx', 20)
        rect.setAttribute('ry', 20)
        rect.setAttribute('width', rectWidth)
        rect.setAttribute('height', rectHeight)
        rect.setAttribute('fill', 'none')
        rect.setAttribute('stroke', '#000000')
        rect.setAttribute('stroke-width', '2')
        nodeUnitOriginal.replaceChild(rect, nodePolygon)
        // lift up text with unit name, change label
        // nodeText.setAttribute('y', rectY + GRAPH_SVG_FONT_SIZE * 1.0)
        // add foreignObject object
        const foreignDivTitle = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        foreignDivTitle.setAttribute('width', rectWidth)
        foreignDivTitle.setAttribute('height', '50')
        foreignDivTitle.setAttribute('x', rectX)
        foreignDivTitle.setAttribute('y', rectY + 2)
        const innerTitleDiv = document.createElement('div')
        innerTitleDiv.className = 'svg-embedded-title'
        innerTitleDiv.textContent = nodeText.textContent
        foreignDivTitle.appendChild(innerTitleDiv)
        // nodeUnitOriginal.appendChild(foreignDivTitle)
        nodeUnitOriginal.replaceChild(foreignDivTitle, nodeText)
        // draw row of vehicles: image, state, mode, condition
        for (const i in unit.vehicles) {
          const v = unit.vehicles[~~i]
          const unitImageFileName = `vehicle_type_${v.type}_small.webp`
          if (v.type !== 'null') {
            const vehiclePadding = 10
            const vehicleSpaceWidth = rectWidth / unit.vehicles.length
            const vehicleWidth = rectWidth / unit.vehicles.length - vehiclePadding * 2
            const vehicleHeight = rectHeight - GRAPH_SVG_FONT_SIZE * 1.1 - vehiclePadding * 2
            const posX = rectX + ~~i * vehicleSpaceWidth + (~~i + 1) * vehiclePadding
            const framePosY = rectY + GRAPH_SVG_FONT_SIZE * 1.1 + vehiclePadding
            let posY = framePosY
            // add vehicle frame
            const vehicleRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
            vehicleRect.setAttribute('x', posX - ~~i * vehiclePadding)
            vehicleRect.setAttribute('y', framePosY)
            vehicleRect.setAttribute('rx', 10)
            vehicleRect.setAttribute('ry', 10)
            vehicleRect.setAttribute('width', vehicleWidth)
            vehicleRect.setAttribute('height', vehicleHeight)
            vehicleRect.setAttribute('fill', 'none')
            vehicleRect.setAttribute('stroke', '#abbb25a6')
            vehicleRect.setAttribute('stroke-width', '2')
            nodeUnitOriginal.appendChild(vehicleRect)
            // add cloned node: vehicle name
            posY += GRAPH_SVG_FONT_SIZE * 1.0
            const nodeVehicleName = nodeText.cloneNode(true)
            nodeVehicleName.textContent = v.name
            nodeVehicleName.setAttribute('x', posX + vehicleWidth / 2)
            nodeVehicleName.setAttribute('y', posY)
            if (v.hasConnection) {
              nodeVehicleName.setAttribute('stroke', GRAPH_SVG_COLOR_ONLINE)
            } else {
              nodeVehicleName.setAttribute('stroke', GRAPH_SVG_COLOR_OFFLINE)
            }
            nodeUnitOriginal.appendChild(nodeVehicleName)
            // add unit image and place to the center
            const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image')
            imageElement.setAttribute('href', `/images/${unitImageFileName}`)
            imageElement.setAttribute(
              'x',
              posX + (vehicleSpaceWidth - vehicleSpaceWidth * GRAPH_SVG_IMAGE_SCALE) / 2 - 10
            )
            imageElement.setAttribute('y', posY - 10)
            imageElement.setAttribute('width', vehicleSpaceWidth * GRAPH_SVG_IMAGE_SCALE)
            imageElement.setAttribute('height', vehicleSpaceWidth * GRAPH_SVG_IMAGE_SCALE)
            nodeUnitOriginal.appendChild(imageElement)
            // add cloned node: vehicle status, place at bottom with readiness status
            const nodeVehicleStatus = nodeVehicleName.cloneNode(true)
            nodeVehicleStatus.setAttribute('y', rectY + rectHeight - GRAPH_SVG_FONT_SIZE * 0.65)
            nodeVehicleStatus.setAttribute(RECT_VEHICLE_CLICKABLE_TITLE_ID, v.vehicleId)
            const vClrs = getActiveStatusConfig(v)
            if (!v.isAvailable) {
              nodeVehicleStatus.setAttribute('stroke', GRAPH_SVG_COLOR_ONMAINTENANCE)
              nodeVehicleStatus.textContent = 'На обслуговуванні'
            } else {
              nodeVehicleStatus.setAttribute('stroke', vClrs.titleStroke)
              nodeVehicleStatus.textContent = vClrs.text
            }
            nodeUnitOriginal.appendChild(nodeVehicleStatus)
            if (v.isAvailable && (v.hasWorkMode || v.hasReadinessDegree)) {
              // add rect with onClick handler
              const rectClick = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
              rectClick.setAttribute('class', RECT_VEHICLE_CLICKABLE_CLASS)
              rectClick.setAttribute(RECT_VEHICLE_CLICKABLE_RECT_ID, v.vehicleId)
              rectClick.setAttribute('x', posX - ~~i * vehiclePadding + 4)
              rectClick.setAttribute('y', rectY + rectHeight - GRAPH_SVG_FONT_SIZE * 1.75)
              rectClick.setAttribute('rx', 5)
              rectClick.setAttribute('ry', 5)
              rectClick.setAttribute('width', vehicleWidth - 8)
              rectClick.setAttribute('height', GRAPH_SVG_FONT_SIZE * 1.4)
              rectClick.setAttribute('stroke', vClrs.frameStroke)
              rectClick.setAttribute('cursor', 'pointer')
              rectClick.setAttribute('fill', 'transparent')
              rectClick.setAttribute('stroke-width', '5')
              nodeUnitOriginal.appendChild(rectClick)
            }
          }
        }
      }
    }
  }
}

const readStorageState = () => {
  const storedState = localStorage.getItem('hierarchyGraph')
  const state = storedState
    ? JSON.parse(storedState)
    : {
        value: INITIAL_VALUE,
        tool: TOOL_PAN,
      }
  return state
}

const saveStorageState = (newTool, newValue) => {
  const state = readStorageState()
  if (newTool) {
    state.tool = newTool
  }
  if (newValue) {
    state.value = newValue
  }
  localStorage.setItem('hierarchyGraph', JSON.stringify(state))
}

function HierarchyGraph({ vehicles = [], hierarchy = [], ...props }) {
  const { auth } = useContext(authContext)
  const { showSnackbar } = useSnackbar()
  const refGraphComponent = useRef(null)
  const refGraphvizRoot = useRef(null)
  const refDialog = useRef(null)
  const [isReady, setIsReady] = useState(false)
  const [dataStructure, setDataStructure] = useState([])
  const [graphData, setGraphData] = useState('digraph {}')
  const [svgWithImages, setSvgWithImages] = useState(null)
  const [actualSVGInnerGroup, setActualSVGInnerGroup] = useState(null)
  const [actualSVGViewBox, setActualSVGViewBox] = useState('0 0 800 800')
  const [isActualSVGReady, setIsActualSVGReady] = useState(false)
  const [isClickHandersReady, setIsClickHandersReady] = useState(false)
  const [frameSize, setFrameSize] = useState({ width: 800, height: 800 })
  const Viewer = useRef(null)
  const [tool, setTool] = useState(readStorageState()?.tool ? readStorageState()?.tool : TOOL_PAN)
  const [value, setValue] = useState(readStorageState()?.value ? readStorageState()?.value : INITIAL_VALUE)
  const [isDialogActive, setIsDialogActive] = useState(false)
  const [chosenVehicle, setChosenVehicle] = useState(null)
  const [checkIntervalHandler, setCheckIntervalHandler] = useState(null)
  const apiWorkplaceUrl = useApiUrl('workplace')
  const [, workplace] = useGetRequest(apiWorkplaceUrl)

  useEffect(() => {
    if (isActualSVGReady && !readStorageState()?.value) {
      Viewer.current.fitToViewer()
    }
    // isActualSVGReady && Viewer.current.fitToViewer()
  }, [isActualSVGReady])

  useEffect(() => {
    const unitStructure = []
    const personalData = {
      isCommander: auth?.data?.position?.isCommander,
      unitId: auth?.data?.unit?.entityId,
    }
    fillNodeStructure(unitStructure, '', hierarchy, vehicles, personalData)
    setDataStructure(unitStructure)
    const bufferNodes = []
    const bufferLinks = []
    for (const r of unitStructure) {
      const unitWidth = Math.max(GRAPH_SVG_UNIT_WIDTH * r.vehicles.length, GRAPH_SVG_UNIT_WIDTH)
      bufferNodes.push(
        `"${r.unitId}" [ label="${r.unitName}" shape="rect" fixedsize="true" class="warunit ${CLASS_UNIT_ID_PREFIX}${r.unitId}" width="${unitWidth}pt" height="${GRAPH_SVG_UNIT_HEIGHT}" fontsize="${GRAPH_SVG_FONT_SIZE}" fontname="${GRAPH_SVG_FONT_NAME}" ] `
      )
      if (r.parentId) {
        bufferLinks.push(`"${r.parentId}" -> "${r.unitId}" [ arrowhead="none" ]`)
      }
    }
    const graphDataStr = `digraph {
      nodesep=2.0
      ranksep=2.0
      ${bufferNodes.join('\n')}
      ${bufferLinks.join('\n')}
    }`
    setGraphData(graphDataStr)
    setIsReady(true)
  }, [vehicles, hierarchy, auth])

  useEffect(() => {
    if (isReady && refGraphvizRoot.current && !svgWithImages && !isActualSVGReady) {
      const oldSVG = refGraphvizRoot.current.querySelector('svg')
      if (oldSVG) {
        const newSVG = oldSVG.cloneNode(true)
        const nodeUnitList = newSVG.querySelectorAll('.warunit')
        for (const node of nodeUnitList) {
          improveNodeSvg(node, dataStructure)
        }
        setSvgWithImages(newSVG)
        // prepare SVG content to Viewer
        const bounds = refGraphComponent.current.getBoundingClientRect()
        setFrameSize(bounds)
        const viewBoxData = newSVG.getAttribute('viewBox')
        setActualSVGViewBox(viewBoxData)
        const topGroup = newSVG.querySelector('#graph0')
        setActualSVGInnerGroup(topGroup.outerHTML)
        setIsActualSVGReady(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, refGraphvizRoot, svgWithImages])

  const getActualSVGGroup = () => {
    return svgWithImages && isActualSVGReady ? <g dangerouslySetInnerHTML={{ __html: actualSVGInnerGroup }} /> : <g></g>
  }

  useEffect(() => {
    if (svgWithImages && isActualSVGReady && !isClickHandersReady) {
      const clickableRects = document.getElementsByClassName(RECT_VEHICLE_CLICKABLE_CLASS)
      for (const e of clickableRects) {
        const vId = e.dataset?.vehicleId
        if (vId) {
          e.addEventListener('click', (e) => {
            const vehicle = findVehicleById(dataStructure, vId)
            if (vehicle.isChangesAllowed) {
              setChosenVehicle(vehicle)
              setIsDialogActive(true)
              const tf = document.getElementById('vehicleStatusDialog')
              tf.style.left = `${e.offsetX}px`
              tf.style.top = `${e.offsetY}px`
            }
          })
        }
      }
      const handler = setInterval(checkTimeoutReadinessStates, CHECK_STATE_INTERVAL_VALUE)
      setCheckIntervalHandler(handler)
      setIsClickHandersReady(true)
    }
    return () => {
      clearInterval(checkIntervalHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgWithImages, isActualSVGReady])

  const getAvailableOptions = (vehicle) => {
    if (vehicle?.hasWorkMode) {
      return workModeOptions
    }
    if (vehicle?.hasReadinessDegree) {
      if (vehicle?.actualReadinessDegree && workplace?.appSettings?.vehicle?.appId === vehicle?.appId) {
        return readinessDegreeOptionsDepend[vehicle?.actualReadinessDegree]
      } else {
        return readinessDegreeOptions
      }
    }
    return []
  }

  const checkTimeoutReadinessStates = () => {
    for (const u of dataStructure) {
      if (u.vehicles) {
        for (const v of u.vehicles) {
          if (v.hasReadinessDegree && v.actualReadinessDegree && v.actualChanged) {
            const elementsFrame = document.querySelectorAll(`[${RECT_VEHICLE_CLICKABLE_RECT_ID}="${v.vehicleId}"]`)
            const elementsTitle = document.querySelectorAll(`[${RECT_VEHICLE_CLICKABLE_TITLE_ID}="${v.vehicleId}"]`)
            for (const e of elementsFrame) {
              const vClrs = getActiveStatusConfig(v)
              e.setAttribute('stroke', vClrs.frameStroke)
            }
            for (const e of elementsTitle) {
              const vClrs = getActiveStatusConfig(v)
              e.textContent = vClrs.text
              e.setAttribute('stroke', vClrs.titleStroke)
            }
          }
        }
      }
    }
  }

  const assignReadinessAndWorkMode = async (newStatus) => {
    if (chosenVehicle?.hasWorkMode) {
      await saveChangedStates({
        work_mode: newStatus.value,
      })
    } else if (chosenVehicle?.hasReadinessDegree) {
      await saveChangedStates({
        readiness_degree: newStatus.value,
      })
    }
    setIsDialogActive(false)
  }

  const saveChangedStates = async (params) => {
    const response = await httpRequests.put(
      getApiUrl('unit_working_modes_vehicle', { vehicleId: chosenVehicle.vehicleId }),
      params
    )
    if ([200, 201].includes(response.status)) {
      const v = findVehicleById(dataStructure, chosenVehicle.vehicleId)
      if (v) {
        if (v.hasWorkMode) {
          v.workMode = response.data.data.work_mode
          v.actualWorkMode = response.data.data.actual_work_mode
        }
        if (v.hasReadinessDegree) {
          v.readinessDegree = response.data.data.readiness_degree
          v.actualReadinessDegree = response.data.data.actual_readiness_degree
        }
        v.actualChanged = response.data.data.actual_changed
        const elementsFrame = document.querySelectorAll(`[${RECT_VEHICLE_CLICKABLE_RECT_ID}="${v.vehicleId}"]`)
        const elementsTitle = document.querySelectorAll(`[${RECT_VEHICLE_CLICKABLE_TITLE_ID}="${v.vehicleId}"]`)
        for (const e of elementsFrame) {
          const vClrs = getActiveStatusConfig(v)
          e.setAttribute('stroke', vClrs.frameStroke)
        }
        for (const e of elementsTitle) {
          const vClrs = getActiveStatusConfig(v)
          e.textContent = vClrs.text
          e.setAttribute('stroke', vClrs.titleStroke)
        }
        showSnackbar({
          message: 'Статус змінено успішно',
          status: SnackbarStatus.success,
        })
        setChosenVehicle(v)
        setDataStructure(dataStructure)
      }
    } else {
      showSnackbar({
        message: 'Помилка зміни статусу',
        status: SnackbarStatus.error,
      })
    }
  }

  return (
    <div className="tree_graph" {...props} ref={refGraphComponent}>
      <div
        className={`dialog_wrapper ${isDialogActive ? 'shown' : 'onback'}`}
        onClick={() => setIsDialogActive(false)}></div>
      <div id="vehicleStatusDialog" className={`dialog_form ${isDialogActive ? 'shown' : 'onback'}`} ref={refDialog}>
        {isDialogActive && (
          <Select
            label={
              chosenVehicle?.hasWorkMode
                ? `Режим ⇒ ${chosenVehicle?.workMode}`
                : `Готовність ⇒ ${chosenVehicle?.readinessDegree}`
            }
            name="vehicleStatus"
            value={
              chosenVehicle?.hasWorkMode
                ? chosenVehicle?.workMode || ''
                : chosenVehicle?.hasReadinessDegree
                ? chosenVehicle?.readinessDegree || ''
                : ''
            }
            options={getAvailableOptions(chosenVehicle)}
            onChange={({ option }) => assignReadinessAndWorkMode(option)}
            selectedOption={getCurrentOption(chosenVehicle)}
          />
        )}
      </div>
      <div ref={refGraphvizRoot} style={{ display: 'none' }}>
        {!isActualSVGReady && (
          <Graphviz
            dot={graphData}
            options={{
              fit: true,
              zoom: false,
              width: frameSize.width,
              height: frameSize.height,
            }}
          />
        )}
      </div>
      {isActualSVGReady && (
        <ReactSVGPanZoom
          className="hierarchy_graph_actual_svg"
          ref={Viewer}
          width={frameSize.width}
          height={frameSize.height}
          tool={tool}
          onChangeTool={(x) => {
            setTool(x)
            saveStorageState(x, null)
          }}
          value={value}
          onChangeValue={(x) => {
            setValue(x)
            saveStorageState(null, x)
          }}>
          <svg width={frameSize.width} height={frameSize.height} viewBox={actualSVGViewBox}>
            {getActualSVGGroup()}
          </svg>
        </ReactSVGPanZoom>
      )}
      <style jsx="true">{`
        .tree_graph {
          position: relative;
          min-height: 800px;
          user-select: none;
          margin-top: 1rem;
        }
        .hierarchy_graph_actual_svg > svg > rect {
          fill: transparent;
        }
        .svg-embedded-title {
          font-size: 28px;
          line-height: 0.75;
          text-align: center;
        }
        .dialog_wrapper,
        .dialog_form {
          z-index: -1;
          visibility: hidden;
        }
        .dialog_wrapper.shown,
        .dialog_form.shown {
          z-index: 10;
          visibility: visible;
        }
        .dialog_wrapper {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: #4b4b4b2b;
          border-radius: 10px;
        }
        .dialog_form {
          position: absolute;
          top: 0;
          left: 0;
          width: 200px;
          height: 60px;
          background-color: #fcfff1;
          border-radius: 7px;
          border: 1px solid #8f832f;
          box-shadow: #402a06 0px 0px 15px;
        }
        .dialog_form label {
          font-size: 14px;
          color: #000;
          text-align: center;
          width: 100%;
          padding: 0 0.5rem;
          box-sizing: border-box;
          margin-top: 0.25rem;
        }
        .dialog_form input {
          font-size: 16px;
        }
      `}</style>
    </div>
  )
}

export default HierarchyGraph
